@import '../../constants';

.billing-information-container {
  display: flex;
  flex-direction: column;

  &.shrank {
    height: $order-billing-height;
  }

  select {
    max-height: 35px;
    height: 35px;
    padding: 0 3px;
    text-indent: 8px;
  }

  .billing-information-title-container {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .primary-button {
      max-width: 10rem;
    }
  }

  .billing-information-form {
    display: flex;
    padding-top: 1rem;
    padding-bottom: 0;
    flex-wrap: wrap;

    .use-billing-information-container {
      display: flex;
      width: 100%;
      justify-content: center;
      padding-top: 1rem;
    }
  }

  .primary-button {
    margin-top: 1rem;
    width: 50%;
    align-self: center;
    max-width: 20rem;
  }

  .action-button {
    background-color: white;
    color: $xvoucher-color-blue-light;
    padding: 0.1rem 0.8rem;
    border: 1px solid $xvoucher-color-blue-light;
    border-radius: 4px;
    font-size: 1rem;
    cursor: pointer;
    user-select: none;

    &:hover {
      background-color: $xvoucher-color-blue-light;
      color: white;
    }
  }
}

.billing-information-display {
  display: flex;
  flex-direction: column;
  padding-top: 1rem;
  padding-left: 1rem;
  width: 80%;
  height: 100%;

  .billing-information-name {
    margin-bottom: 0.4rem;
  }

  h4 {
    overflow: hidden;
    text-overflow: ellipsis;
    margin-bottom: 0.5rem;
    font-size: 0.9rem;
  }
}

@media (max-width: 835px) {
  .billing-information-container {
    margin-bottom: 1rem;
  }
}

@media (max-width: 525px) {
  .billing-information-container {
    .primary-button {
      width: 100%;
    }
  }
}
