.slide-button {
  overflow: hidden;
  white-space: nowrap;

  .slider {
    width: 200%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .content-wrapper {
      width: 50%;
    }
  }

  .left {
    margin-left: -100%;

    &.animated {
      animation: slide-left 1s;
    }
  }

  .right {
    margin-left: 0;

    &.animated {
      animation: slide-right 1s;
    }
  }
}

@keyframes slide-left {
  from {
    margin-left: 0;
  }

  to {
    margin-left: -100%;
  }
}

@keyframes slide-right {
  from {
    margin-left: -100%;
  }

  to {
    margin-left: 0;
  }
}
