@import '~loaders.css/src/animations/ball-scale-multiple';
@import '~loaders.css/src/animations/ball-pulse';
@import 'constants', 'input';

* {
  box-sizing: border-box;
}

html,
body,
#root {
  height: 100%;
  font-size: 14px;
}

#root {
  display: flex;
  flex-direction: column;
}

textarea {
  resize: vertical;
}

a {
  color: $secondary-color-blue;
  text-decoration: inherit;

  &.xvoucher-decoration {
    color: $primary-color-blue;

    &:hover {
      text-decoration: underline;
    }
  }
}

// remove chrome blue highlight
:focus {
  outline: none;
}

// TODO: make mixin
.container {
  padding: 1.5rem;
  flex: 1 0 auto;
}

// TODO: make mixin
.card {
  background: white;
  padding: 0 1rem;
}

.margin-top {
  margin-top: 1rem;
}

.margin-top-half {
  margin-top: 0.5rem;
}

.margin-bottom {
  margin-bottom: 1rem;
}

.base-margin {
  margin: 1rem 0;
}

.no-padding-top {
  padding-top: 0;
}

// TODO: refactor using mixin or adding content to another file
.d-flex {
  display: flex;

  &.justify-flex-end {
    justify-content: flex-end;
  }

  &.justify-center {
    justify-content: center;
  }

  &.justify-between {
    justify-content: space-between;
  }

  &.align-center {
    align-items: center;
  }

  &.fullWidth {
    width: 100%;
  }

  &.wrap {
    flex-wrap: wrap;
  }
}

.d-col {
  display: flex;
  flex-direction: column;

  &.fullWidth {
    width: 100%;
  }
}

.col-6 {
  width: 50%;
  flex-direction: column;
  display: flex;

  &.justify-center {
    justify-content: center;
  }
}

.col-4 {
  width: 33%;
  flex-direction: column;
  display: flex;

  &.justify-center {
    justify-content: center;
  }
}

.col-8 {
  width: 67%;
  flex-direction: column;
  display: flex;

  &.justify-center {
    justify-content: center;
  }
}

h2,
h3,
h4,
h5 {
  margin: 0;
  font-weight: normal;

  &.bold {
    font-weight: bold;
  }
}

.align-center {
  display: flex;
  align-items: center;
}

.justify-center {
  display: flex;
  justify-items: center;
}

.expand-button {
  color: $xvoucher-color-blue-light;
  background: none;
  border: none;
  font-size: 1.1rem;
  font-weight: 600;
  transition: transform 0.3s ease-in;
  cursor: pointer;

  &:hover {
    color: $xvoucher-color-blue;
  }

  &.rotated {
    transform: rotateZ(180deg);
  }
}

.primary-button {
  background-color: $xvoucher-color-blue-light;
  color: white;
  padding: 0.5rem 2rem;
  border: 1px solid $xvoucher-color-blue-light;
  font-size: 1rem;
  cursor: pointer;
  user-select: none;
  font-weight: 600;

  border-radius: 4px;
  transition: background-color 0.4s, color 0.4s, border-color 0.4s;

  &.invoice {
    padding: 0.5rem 1.5rem;
    border: none;
    font-size: 1.2rem;
  }

  &.full-size {
    width: 100%;
    height: 3rem;
  }

  &.margin-top {
    margin-top: 0.6rem;
  }

  &.bg-gray {
    background: $secondary-color-gray;
    color: black;
  }
  &.bg-green {
    background: $primary-color-green;
  }

  &.bg-red {
    background: $error-red;
  }

  &.success {
    &:hover {
      background-color: $success-green;
      color: white;
      border-color: $success-green;
    }
  }

  &.cancel {
    background-color: white;
    color: black;
    &:hover {
      background-color: $error-red;
      color: white;
      border-color: $error-red;
    }
  }

  &:disabled {
    background: $primary-color-gray;
    border-color: $primary-color-gray;
    cursor: not-allowed;
    color: white;
    &:hover {
      background: $primary-color-gray;
      border-color: $primary-color-gray;
      cursor: not-allowed;
    }
  }
}

.button {
  &--big {
    min-height: 3rem;
    min-width: 8rem;
  }

  &--disabled {
    background: grey;
    cursor: not-allowed;
  }

  &--error {
    color: white;
    background: $error-red;
  }

  &--success {
    color: white;
    background: $primary-color-green;
  }

  &--min-width {
    min-width: 8rem;
  }
}

.link-button {
  display: inline;
  background-color: transparent;
  border: none;
  padding: 0 0.3rem;
  color: white;
  font-weight: 600 !important;
  font: inherit;
  cursor: pointer;

  &.underlined {
    text-decoration: underline;
  }
}

.loading-wrapper {
  align-items: center;
  display: flex;
  justify-content: center;
  width: 100%;

  // These two classes are required by react-loaders
  .loader-hidden {
    display: none;
  }
  .loader-active {
    display: block;
  }
}

button:disabled,
a:disabled {
  cursor: not-allowed;
}

.ml-1r {
  margin-left: 1rem;
}

.loader-hidden {
  display: none;
}
.loader-active {
  display: block;
}

hr {
  // Reset default HR
  unicode-bidi: unset;
  margin-block-start: unset;
  margin-block-end: unset;
  margin-inline-start: unset;
  margin-inline-end: unset;
  overflow: unset;
  border-style: unset;
  border-width: unset;

  min-height: 2px;
  min-width: 100%;
  background: linear-gradient(to right, transparent 0%, lightgrey 20%, lightgrey 80%, transparent 100%);
  border-radius: 10px;
  margin: 0.3rem 0;
  display: block;
}

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #999;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #777;
}
