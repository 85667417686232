@import '../_constants.scss';

$modal-transition-time: 0.4s;
$footer-height: 150px;

.main-component-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
  align-items: center;
  justify-content: center;

  .main-component-modal-container {
    display: flex;
    flex-direction: column;
    flex: 0 1 100%;
    height: 90%;

    width: 35rem;
    justify-content: center;
    padding: 1rem 0.5rem;

    transition: width $modal-transition-time, max-height $modal-transition-time;

    .cancel-button {
      display: flex;
      align-items: center;
      background: transparent;
      border: none;
      color: white;
      font-size: 1rem;
      font-weight: bold;
      align-self: flex-start;
      padding: 0.8rem;
      padding-top: 0;
      cursor: pointer;

      &:hover {
        text-decoration: underline;

        &:disabled {
          text-decoration: none;
          cursor: not-allowed;
        }
      }

      svg {
        margin-right: 10px;
      }
    }

    .visual-modal-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 1rem 0.5rem;
      background: white;
      border: none;
      border-radius: 1rem;
      overflow: hidden;
      width: 100%;

      .logo-container {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        padding-bottom: 0.8rem;

        img {
          max-width: 225px;
        }
      }

      .order-information-container {
        display: flex;
        flex-direction: column;
        width: 100%;
        justify-self: center;
        overflow-x: hidden;

        .order-billing-component-container {
          display: flex;
          flex-direction: column;
        }

        .summary-component-container {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
        }
      }

      .stripe-component-container {
        padding: 0.5rem 0 0 0;
        width: 80%;
        align-self: center;
      }

      .error-component-container {
        display: flex;
        width: 100%;
        justify-content: center;
        align-items: center;
        color: red;

        &.shorter {
          width: 80%;
        }

        svg {
          margin-right: 0.5rem;
        }
      }
    }

    .billing-confirmation {
      display: flex;
      flex-direction: column;
      padding: 1rem;

      & > h4 {
        padding: 1rem 0;
        font-weight: 500;
      }

      h3 {
        padding-bottom: 1rem;
      }

      .billing-confirmation-addresses {
        display: flex;
        flex-direction: column;

        .billing-confirmation-address {
          flex-grow: 1;
        }
      }

      .modal-buttons {
        display: flex;
        justify-content: center;
        padding-top: 1rem;

        .primary-button {
          width: 40%;
          max-width: 15rem;
        }
      }
    }
  }
}

// @media (max-height: 700px) {
//   .main-component-container {
//     .main-component-modal-container {
//       max-height: 100%;
//     }
//   }
// }

@media (max-width: 1200px) {
  .main-component-container {
    .main-component-modal-container {
      max-height: 100%;
    }
  }
}

//mobile media
@media (max-width: 920px) {
  .main-component-container {
    .main-component-modal-container {
      max-height: 100%;
      max-width: inherit;
    }
  }
}
