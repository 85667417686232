@import '../../constants';

.order-summary-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  background: white;
  padding: 0 1rem;
  min-height: 98px;

  h2 {
    margin-bottom: 1rem;
  }

  .order-summary-subtotals-container {
    display: flex;
    flex-direction: column;
    padding-top: 1rem;
    margin-bottom: 1rem;
    justify-content: flex-end;

    .summary-grid {
      display: grid;
      grid-template-columns: 1fr auto;
      grid-gap: 0.5rem 2rem;

      .summary-grid-item {
        text-align: end;
        font-weight: 100;
      }

      .green {
        color: $success-green;
      }

      h5.description {
        text-align: end;
        margin: 0.3rem 0;
        font-weight: normal;

        svg {
          color: $primary-color-blue;
        }
      }

      .order-summary-subtotal-container {
        display: flex;
        flex-direction: row;
        width: 100%;
        justify-content: space-between;
        padding: 1rem;

        h4 {
          font-weight: 500;
        }

        &.highlighted {
          border: 1px solid $primary-color-gray;
          border-radius: 5px;
          background: $secondary-color-light-gray;
          padding: 0.6rem 1rem;
        }
      }

      .order-summary-total {
        font-weight: 900;

        &.amount {
          color: darkorange;
        }
      }
    }
  }
}
