@import '../constants';

.xv-pay-container {
  background: linear-gradient(135deg, $xvoucher-color-blue 0%, $xvoucher-color-green 100%);
  display: flex;
  width: 100%;
  height: 100vh;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  background-size: 150%;

  .main-page-modal-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    z-index: 2;
  }

  .animation-line {
    position: absolute;
    width: 100%;
    height: 100vh;
    overflow: hidden;

    svg {
      filter: blur(75px);
      transform: rotate(145deg) translate(10%, 155%);
      position: relative;
      top: 0;
      right: 0;
      width: 6000px;
      animation: animateLine 45s ease-in-out infinite;
    }
  }

  @keyframes animateLine {
    0% {
      top: 0;
      right: 0;
    }

    50% {
      top: 1500px;
      right: 1500px;
    }

    100% {
      top: 0;
      right: 0;
    }
  }
}

//mobile media
@media (max-width: 920px) {
  .animation-line {
    display: none;
  }
}
