.invoice-flex-centered {
  display: flex;
  justify-content: center;
}

.invoice-container {
  display: flex;
  flex-direction: column;
  min-width: 1200px;

  iframe {
    visibility: hidden;
  }

  input {
    padding: 0.5rem 0.8rem;
    margin: 0.5rem 0.5rem;
    font-size: 1.2rem;
  }

  label {
    font-size: 1.2rem;
  }
}

.invoice-header {
  display: flex;
  justify-content: flex-end;
  align-items: center;

  .invoice-email {
    width: 400px;
  }
}
