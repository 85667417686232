@import '../constants';

.footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  align-self: center;
  flex: 1 0 auto;

  &.onlyLinks {
    justify-content: normal;
    padding-top: 1rem;
  }

  p {
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;

    .footer-text {
      color: white;
      padding: 0 0.7rem;
    }

    &.dark {
      color: $xvoucher-color-blue;

      .footer-text {
        color: $xvoucher-color-blue;
      }
    }
  }

  .bigger {
    font-size: 1.4rem;
    font-weight: bold;
  }

  .transparent {
    opacity: 0.35;
  }

  img {
    max-height: 50px;
  }
}
