@import '../constants';

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: $header-background-color;
  background: #5b9044;
  background: linear-gradient(159deg, $xvoucher-color-green 0%, $xvoucher-color-blue 100%);
  min-height: $header-height;
  padding: 0 $header-height/2;

  &.no-background {
    background: transparent;
  }

  &.color-white {
    background: white;
  }

  &-logo {
    display: flex;

    &-img {
      max-height: $header-height;
      padding: 0.5rem;
      height: 100%;
    }

    &__container {
      display: flex;
      align-items: center;
    }
  }
}
