.Toastify__toast-container--top-right {
  width: fit-content;
  max-width: 600px;
}

@media (max-width: 600px) {
  .Toastify__toast-container--top-right {
    left: 0px;
  }
}
