@import '../../constants';

.order-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0 1rem;

  &.shrank {
    height: $order-billing-height;
  }

  .order-information-title-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 1rem;

    .order-title {
      color: black;
    }
  }

  .order-items-grid {
    display: grid;

    span {
      font-size: $order-data-font-size;
    }

    .order-items-container {
      display: flex;
      flex-direction: column;
      padding-left: 1rem;
      overflow: hidden;

      h4 {
        font-size: 0.9rem;
      }

      &.column-span {
        grid-column: span 2;
      }

      .first-order-item-container {
        display: flex;

        .first-order-item-name {
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
        }

        span,
        h4 {
          margin-bottom: 0.5rem;
        }
      }

      .first-order-item-info {
        color: $primary-color-gray;
        width: fit-content;
        cursor: pointer;
      }
    }

    .order-subtotal-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: max-content;
      grid-row: 2;
      grid-column: 2;
      justify-self: end;
      align-self: end;

      .inline {
        display: flex;
        flex-direction: row;
        width: 100%;
        justify-content: space-between;
        align-self: flex-end;
      }

      .order-subtotal {
        align-self: flex-end;
        margin-right: 2rem;
        margin-bottom: 0.5rem;

        &.green {
          color: $success-green;
        }
      }
    }
  }
}
