@import '../../../constants';

.form-field-input-container {
  padding: 0.2rem;
  width: 50%;

  &.full-width {
    width: 100%;
  }
}

@media (min-width: 835px) and (max-width: 1150px) {
  .form-field-input-container {
    width: 100%;
  }
}

@media (max-width: 735px) {
  .form-field-input-container {
    width: 100%;
  }
}
