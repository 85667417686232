@import '../constants';

.cobranding-wrapper {
  color: white;
  font-weight: 600;

  p {
    margin: 0;
    text-align: center;
  }
}
