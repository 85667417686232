@import '../../constants.scss';

.stripe-form-group {
  padding: 0;
  border-style: none;
}

.stripe-form-row {
  display: flex;
  align-items: center;
  border-bottom: 1px solid gray;
}

.StripeElement--webkit-autofill {
  background: transparent !important;
}

.StripeElement {
  width: 100%;
  padding: 11px 15px 11px 0;
}
