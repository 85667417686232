@import './styles/styles';
@import url('https://fonts.googleapis.com/css?family=Roboto&display=swap');

body {
  background: #e6e7e8; // TODO: $secondary-color-gray
  // color: #6e6f72;
  // color: $primary-color-gray;
  color: black;
  margin: 0;
  font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
