// ------ Colors ------
$xvoucher-color-blue: #0d638a;
$xvoucher-color-blue-light: #388ed7;
$xvoucher-color-orange: #ee6c4d;
$xvoucher-color-green: #5b9044;

$primary-color-green: #61a60e;
$primary-color-blue: #0b6281;
$primary-color-gray: #6f6f6f;

$secondary-color-blue: #4fa0b8;
$secondary-color-gray: #e7e7e7;
$secondary-color-light-gray: #f2f2f2;

// ------ Screen sizes ------
$screen-size-xl: 1840px;
$screen-size-l: 1320px;
$screen-size-m: 1120px;
$screen-size-s: 912px;
$screen-size-xs: 668px;
$screen-size-xxs: 465px;

:export {
  primaryColorGreen: $primary-color-green;
  primaryColorBlue: $primary-color-blue;
  primaryColorGray: $primary-color-gray;
}

$success-green: #4bb543;
$error-red: #ff0033;
$error-red-border: #ff4c4c;
$error-font-size: 0.75rem;

$table-header-grey: #f9f9f9;

$input-height: 2.2rem;

$order-data-font-size: 0.9rem;
$order-data-key-font-weight: 700;
$order-data-value-font-weight: 400;

$header-background-color: #232f3e;
$header-height: 6rem;

$footer-background-color: white;
$footer-height: 6rem;

$order-billing-height: 110px;
