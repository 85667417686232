@import '../../constants';

.order-item-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  .order-data-container {
    padding-right: 0.5rem;

    .order-item-name {
      margin-bottom: 0.5rem;
    }
    .order-data-item {
      display: flex;
      flex-direction: row;

      .key {
        font-size: $order-data-font-size;
        margin-right: 0.5rem;
        font-weight: $order-data-key-font-weight;
        width: 7rem;
        padding: 0.2rem 0;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        flex-shrink: 0;
      }

      .value {
        font-size: $order-data-font-size;
        font-weight: $order-data-value-font-weight;
        padding: 0.2rem 0;
      }
    }
  }

  .order-item-pricing-container {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    flex-shrink: 0;

    .order-price {
      margin-bottom: 0.5rem;
      font-weight: initial;

      &.green {
        color: $success-green;
      }
    }
  }
}
