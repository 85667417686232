input,
textarea,
select {
  padding: 0.5rem;
  margin: 0.5rem 0;
  font-size: 1rem;
  display: inline-block;
  border: none;
  border-bottom: 1px solid #ccc;
  // border-radius: 4px;
  box-sizing: border-box;
  font-family: Roboto, 'Courier New', Courier, monospace;
}

textarea {
  resize: vertical;
}
